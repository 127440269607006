import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';

import * as styleSheet from "./blog.module.scss"

const BlogTemplate = (props) => {

	const {
		data: {
			blogPage,
			blogPage: {
				title,
				ACFBlog: {
					blogInfo: {
						left: {
							leftBlurb,
							requestAppointmentLink
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading
							}
						},
						blogContent
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			posts: {
				nodes: posts
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage,
			arrowLightGray,
			arrowRedLocal
		}
	} = props;


	return (
		<Layout>
			<Seo post={blogPage} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.blogtitle} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						{ leftBlurb && <div className={styleSheet.leftblurb} dangerouslySetInnerHTML={{__html: leftBlurb}}></div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styleSheet.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styleSheet.request}>Request appointment</Link> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.servicename} dangerouslySetInnerHTML={{__html: title}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				{ blogContent && <div className="wpcontent" dangerouslySetInnerHTML={{__html: blogContent}}></div> }
				{ posts && posts.length > 0 && (
					<div className={styleSheet.postcontainer}>
						{  posts && posts.map((post, index) => {
							return (
								<Link key={post.id} to={post.link} className={styleSheet.post}>
									<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: post.title}}></span>
									<span className={styleSheet.date} dangerouslySetInnerHTML={{__html: post.date}}></span>
									<div className={styleSheet.excerpt} dangerouslySetInnerHTML={{__html: post.excerpt}}></div>
									<span className={styleSheet.readmore}>Read more <FixedImage image={arrowLightGray} passedClass={styleSheet.icon} /><FixedImage image={arrowRedLocal} passedClass={styleSheet.hovericon} /></span>
								</Link>
							)
						}) }
					</div>
				)}
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  blogPage: wpPage(id: {eq: $id}) {
    id
    title
    ACFBlog {
      blogInfo {
        left {
          leftBlurb
          requestAppointmentLink {
            title
            url
            target
          }
        }
        right {
          introContent {
            blurb
            heading
          }
        }
        blogContent
      }
    }
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  appointmentPage: allWpPage(
    filter: {template: {templateName: {eq: "Appointments Page"}}}
  ) {
    nodes {
      link
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  posts: allWpPost(
    filter: {status: {eq: "publish"}}
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      id
      title
      date(formatString: "MMMM D, Y")
      excerpt
      link
      ACFPostInfo {
        postInfo {
          right {
            postContent
          }
        }
      }
    }
  }
  arrowLightGray: file(name: {eq: "arrow_gray_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
}
`;

export default BlogTemplate;